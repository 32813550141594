
import { defineComponent, onMounted, ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiChanel } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { MBTaggings } from "magicbean-saas-common";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";

interface TaggingValue {
  short_key: string;
  items: Array<TaggingValueItem>;
}

interface TaggingValueItem {
  short_key: string;
  value: string | Array<string>;
}

export default defineComponent({
  name: "channel-information",
  components: { MBTaggings },
  setup() {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const channelSegmentationRef = ref<null | HTMLFormElement>(null);
    const productRequirementRef = ref<null | HTMLFormElement>(null);
    const fulfillmentRequirementRef = ref<null | HTMLFormElement>(null);
    const settlementRequirementRef = ref<null | HTMLFormElement>(null);

    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: route.params.id,
      name: "",
      status: 10,
      specification: 0,
      parent_id: "",
      tagging_data: [] as any[],
      created_at: null,
      updated_at: null,
      created_uid: "",
      updated_uid: "",
      __show: {
        created_user: "",
        updated_user: "",
      },
    });

    const short_key = [
      "distribution_channel_segmentation",
      "distribution_product_requirement",
      "distribution_fulfillment_requirement",
      "distribution_settlement_requirement",
    ];
    const channelSegmentationSource = ref([]);
    const channelSegmentationValues = ref([]);
    const productRequirementSource = ref([]);
    const productRequirementValues = ref([]);
    const fulfillmentRequirementSource = ref([]);
    const fulfillmentRequirementValues = ref([]);
    const settlementRequirementSource = ref([]);
    const settlementRequirementValues = ref([]);

    const getTaggingDataSource = () => {
      return ApiBase.getTaggingGroupData({ short_key: short_key })
        .then(({ data }) => {
          if (data.code == 0) {
            channelSegmentationSource.value =
              data.data.distribution_channel_segmentation.items;
            productRequirementSource.value =
              data.data.distribution_product_requirement.items;
            fulfillmentRequirementSource.value =
              data.data.distribution_fulfillment_requirement.items;
            settlementRequirementSource.value =
              data.data.distribution_settlement_requirement.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const options = ref({
      parent_id: [] as any[],
    });
    const getParentId = (id) => {
      ApiChanel.getDataSource({ id: id, status: 10 })
        .then(({ data }) => {
          options.value.parent_id = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const rules = ref({
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      short_key: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const getDistributionChannelsyData = () => {
      loading.value = true;
      ApiChanel.getChannelInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
            channelSegmentationValues.value =
              data.data.tagging_data.distribution_channel_segmentation?.items ||
              [];
            productRequirementValues.value =
              data.data.tagging_data.distribution_product_requirement?.items ||
              [];
            fulfillmentRequirementValues.value =
              data.data.tagging_data.distribution_fulfillment_requirement
                ?.items || [];
            settlementRequirementValues.value =
              data.data.tagging_data.distribution_settlement_requirement
                ?.items || [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      let taggingArr: Array<TaggingValue> = [];

      let segmentationData = channelSegmentationRef.value?.formData;
      let segmentationItems: Array<TaggingValueItem> = [];
      for (let item in segmentationData) {
        segmentationItems.push({
          short_key: item,
          value: segmentationData[item],
        });
      }
      let segmentationObj: TaggingValue = {
        short_key: "distribution_channel_segmentation",
        items: segmentationItems,
      };
      taggingArr.push(segmentationObj);

      let productReqData = productRequirementRef.value?.formData;
      let productReqItems: Array<TaggingValueItem> = [];
      for (let item in productReqData) {
        productReqItems.push({
          short_key: item,
          value: productReqData[item],
        });
      }
      let productReqObj: TaggingValue = {
        short_key: "distribution_product_requirement",
        items: productReqItems,
      };
      taggingArr.push(productReqObj);

      let fulfillmentReqData = fulfillmentRequirementRef.value?.formData;
      let fulfillmentReqItems: Array<TaggingValueItem> = [];
      for (let item in fulfillmentReqData) {
        fulfillmentReqItems.push({
          short_key: item,
          value: fulfillmentReqData[item],
        });
      }
      let fulfillmentReqObj: TaggingValue = {
        short_key: "distribution_fulfillment_requirement",
        items: fulfillmentReqItems,
      };
      taggingArr.push(fulfillmentReqObj);

      let settlementReqData = settlementRequirementRef.value?.formData;
      let settlementReqItems: Array<TaggingValueItem> = [];
      for (let item in settlementReqData) {
        settlementReqItems.push({
          short_key: item,
          value: settlementReqData[item],
        });
      }
      let settlementReqObj: TaggingValue = {
        short_key: "distribution_settlement_requirement",
        items: settlementReqItems,
      };
      taggingArr.push(settlementReqObj);

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          formData.value.tagging_data = taggingArr;
          ApiChanel.updateChannel(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteDistributionChannels = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Distribution Channels?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiChanel.deleteChannel({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Distribution Channels has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "distribution-channels" });
                });
              } else {
                Swal.fire({
                  title: data.msg,
                  text: data.sub_msg,
                  icon: "error",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    onBeforeMount(() => {
      getParentId(route.params.id);
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      getDistributionChannelsyData();
      Promise.all([getTaggingDataSource(), getDistributionChannelsyData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    });

    return {
      t,
      loading,
      formData,
      rules,
      formRef,
      submitButton,
      deleteButton,
      options,
      channelSegmentationRef,
      productRequirementRef,
      fulfillmentRequirementRef,
      settlementRequirementRef,
      channelSegmentationSource,
      channelSegmentationValues,
      productRequirementSource,
      productRequirementValues,
      fulfillmentRequirementSource,
      fulfillmentRequirementValues,
      settlementRequirementSource,
      settlementRequirementValues,
      submit,
      getDistributionChannelsyData,
      deleteDistributionChannels,
    };
  },
});
